<template>
<div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 d-inline-block mb-0">Projekte</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
 
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body>
            <template slot="header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{$t('message.assemblyPlanning')}}</h3>
                  <table>
                    <tr><td>{{$t('message.responsiblePL')}}</td><td>{{ pl.user.name }}</td></tr>
                    <tr><td>{{$t('message.customer')}}</td><td>{{ project ? project.customer.No : '' }} - {{ project ? project.customer.Name : '' }}</td></tr>
                    <tr>
                      <td>{{$t('message.order')}}</td>
                      <td>
                        <span v-for="(order,i) in project_orders" :key="i">
                        <span v-if="i>0">, </span>{{order.order_no}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>{{$t('message.costUnit')}}</td>
                      <td>
                        <span v-for="(order,i) in project_orders" :key="i">
                          <span v-if="i>0">, </span>{{kostentraeger}}
                        </span>
                        </td>
                      </tr>
                  </table>
                </div>
                <div class="col-6 text-right">
                  <base-button size="sm" type="primary" @click="save" v-if="currentUserRights.includes('project.montage.canedit')" :disabled="project.state === 'ARCHIVED'">
                    {{$t('message.save')}}
                  </base-button>
                </div>
              </div>
            </template>
            <template slot="footer">
              <div class="row">
                <div class="col-12 text-right">
                  <base-button size="sm" type="primary" @click="save" v-if="currentUserRights.includes('project.montage.canedit')"  :disabled="project.state === 'ARCHIVED'">
                    {{$t('message.save')}}
                  </base-button>
                </div>
              </div>
            </template>
            <hot-table :settings="settings" ref="hotTable" :read-only="!currentUserRights.includes('project.montage.canedit')"></hot-table>

          </card>

         
          </div>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->

  </div>
</template>
<script>
  // Charts
  // import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // // Components
  // import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';

  // // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  import Vue from 'vue'
  import Handsontable from 'handsontable'
  import { HotTable } from '@handsontable/vue'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'AssemblyPLanning',
    components: {
      HotTable
    },
    computed: {
      ...mapGetters([
        'project',
        'project_people',
        'project_orders',
        'currentUserRights'
      ]),
      pl() {
        return this.project_people.find(p => p.topic=='PL') || {user:{}}
      },
    },
    watch : {
      project() {
        this.$refs.hotTable.hotInstance.loadData(JSON.parse(this.project.assembly))
      },
      'project_orders' () {
        // Fallback: When an order has not been synched from Dyn
        this.allOrders = this.project_orders.map(order => order.order || {No: order.order_no});
        this.tempOrders = this.project_orders.map(order => order.order_no)
      },
      allOrders () {
        this.kostentraeger = this.allOrders
          .filter(order => this.tempOrders.indexOf(order.No)>=0)
          .map(order => order.Shortcut_Dimension_2_Code)
          .filter((v,i,a)=>a.indexOf(v)==i)
          .join(', ')
      }
    },
    data() {
      return {
        settings: {
          colHeaders: [],
          data: [
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
            ['', '', '', '', '', ''],
          ],
          stretchH: 'all',
          width: '100%',
          height: 600,
          rowHeaders: true,
          licenseKey: 'non-commercial-and-evaluation',
          formulas: true,
          contextMenu: true,
        },
        allOrders: [],
        tempOrders: [],
        showPriceList: false,
        kostentraeger: ''
      }
    },
    methods: {
      async save () {
        const data = this.$refs.hotTable.hotInstance.getData();
        await this.$store.dispatch('updateProject', {id: this.project.id, data: {
          assembly: JSON.stringify(data)
        }});
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Montage erfolgreich gespeichert.',
          type: 'success'
        })
      },  
      copySelection () {

      },
      deleteSelection () {

      }
    },
    mounted() {
      if (this.$refs.hotTable && this.project) {
        this.$refs.hotTable.hotInstance.loadData(JSON.parse(this.project.assembly))
      }
    }
  };
</script>
<style>
@import '~handsontable/dist/handsontable.full.css';

</style>
